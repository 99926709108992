@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Tomorrow:wght@400;500;700&display=swap");
//These are global variables, should be accessed in var(--var_name)

$primary: #2c5090; //--bs-primary
$font-family: "Tomorrow", sans-serif;
$background-color: #f3f5fa;
$leftSideWidth: 240px;
$paddingInnerRightSide: min(51px, 5vw);
$paddingInnerLeftSide: 25px;
$topBarHeight: 80px;

$sidebarTransitionSpeed: 450ms;
