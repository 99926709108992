.tint{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: #000000;
    opacity: 0.4;
}

.fullWindow{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    backdrop-filter: blur(3px);

    display: grid;
    grid-template-rows: 80px 1fr 80px;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
    overflow: auto;

}


.innerWindow{

    background-color: #ffffff;
    border-radius: 8px;
    width: 50%;
    max-width: 800px;
    z-index: 1001;
}

.closeButton{
    position: relative;
    float: right;
    width: 3vw;
    height: 3vw;
    border: 0;
    background-color: inherit;
    border-radius: 8px;
    svg{
        width: 100%;
        height: 100%;
    }


}
.childDiv{
    margin-top: 40px;
    padding: 40px 40px;
    padding-top: 2px;
}