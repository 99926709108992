.error{
  color: #ff2121;
  margin-top: 5px;
}

.formContainer{
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-row-gap: 2rem;
}

.label{
  margin-bottom: 5px;
}