@import "../variables";
.innerWindow {
  position: relative;
  padding-right: $paddingInnerRightSide;
  padding-left: $paddingInnerLeftSide;
  padding-top: 12px;
  overflow: auto;
  width: calc(100% - $leftSideWidth);
  top: $topBarHeight;
  left: $leftSideWidth;
  transition: width $sidebarTransitionSpeed ease-out,
    left $sidebarTransitionSpeed ease-out;
}

.overlayStackingContext {
  z-index: 10000;
  position: fixed;
}
