@import 'variables';


.hm{
  font-size: 1rem;
}

/* Import this into a js file, use styles.VARNAME to access the value */
/* Only use for stuff that will be used in a js file */
/*   
import styleConsts from "../styles/export.module.scss";
console.log(styleConsts) */
:export{

  leftSideWidth: $leftSideWidth;
  paddingInnerLeftSide: $paddingInnerLeftSide;

}