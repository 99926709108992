@import "styles/variables";


.sidebarContainer{
  position: fixed;
  z-index: 50;
  height: 100%;
  left:0px;
  width: $leftSideWidth;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom:10px;
  overflow-y: auto;
  transition: left $sidebarTransitionSpeed ease-out;


}
.logoContainer{
  display: flex;
  width: $leftSideWidth;
  height: $topBarHeight;
  justify-content: center;
  align-items: center;
  border-bottom:1px solid #cccccc ;
}


.helpBox{
  background-color:#F3F5FA;
  width: calc($leftSideWidth *0.8);
  font-size: 0.75rem !important;
  padding-top: 1rem;
  padding-left: 0.5rem;
  margin-left: 1.25rem;
  margin-bottom:24px;
  border-radius:10px;
  display: flex;
  flex-direction:column;
  justify-content:space-evenly;
}


//This is for sidebar link page
.containerButton{
  padding: 1.25rem 1.5rem;
  padding-right:0px;
}

.sidebarAnchor{
  text-decoration: none;
  transition: 0.2s color;
  font-size: 0.9rem;
  font-weight: 700;
  display: grid;
  grid-template-columns: 24px auto;
  grid-column-gap: 20px;
  align-items: center;
  path{
    transition: 0.2s fill;
  }
}
.sidebarAnchor:hover {
  cursor: pointer;
  color: #333333 ;
  path{
    fill: #333333;
  }
}

.sidebarAnchorActive{
  color: #0050C8;
  path {
    fill: #0050C8;
  }
}

.sidebarAnchorNotActive{
  color: #6c757d;
  path{
    fill: #6c757d;
  }
}

$widthButton : 34px;
$heightButton: 60px;
.toggleSidebar{
  
  position: fixed;
  top: calc( (100% + $topBarHeight) * 0.5 - $heightButton * 0.5);
  left: calc($leftSideWidth - $widthButton );
  z-index: 100;
  transition: left $sidebarTransitionSpeed ease-out;
  //Close styles
  #infoCloseSidebar{
    display: none;
    background: #000000;
    color: #ffffff;
    border-radius: 6px;
    padding:6px 0px;
    font-size: 0.75rem;
    width: calc( $widthButton + 24px);


  }
  .sidebarCloseButton{
    position: absolute;
    display: block;
    width: $widthButton ;
    height: $heightButton;
    z-index: 101;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background: #d0d1d6;
    border: 0px;
    padding-left: 6px;


  }.sidebarCloseButton:hover{
    background: #b4b5b9;
    #infoCloseSidebar{
      position: absolute;
      display: block;
      top: $heightButton;
      right: calc(($widthButton + 24px) * -0.25);
    }
  }
  //Open style
  #infoOpenSidebar{
    display: none;
    position: absolute;
    background: #000000;
    color: #ffffff;
    border-radius: 6px;
    padding:6px 0px;
    font-size: 0.75rem;
    width: calc( $widthButton + 24px);



  }
  .sidebarOpenButton{
    position: absolute;
    display: none;
    margin-left: $widthButton;
    width: $widthButton ;
    height: $heightButton;
    z-index: 101;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background: #d0d1d6;
    border: 0px;
    padding-left: 6px;




  }.sidebarOpenButton:hover{
    background: #b4b5b9;
    #infoOpenSidebar{
      display: block;
      top: $heightButton;
      left: 0px;

    }
  }
}

:export{
  widthButton: $widthButton;
}