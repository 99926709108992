@import "variables";
@import "~bootstrap/scss/bootstrap";

html{
    height: 100%;
}

body{
    background-color: $background-color;
    font-family: $font-family;
    height: 100%;
}

#__next{
    height: 100%;
}