@import "/styles/variables";
.topbarContainer{
    z-index: 50;
    position: fixed;
    width: calc(100% - $leftSideWidth);
    margin-left: $leftSideWidth;
    padding-right: $paddingInnerRightSide;
    height: $topBarHeight;
    z-index: 100;
    background-color: #ffffff;
    border-bottom:1px solid #cccccc ;
    transition: padding-left $sidebarTransitionSpeed ease-out,width $sidebarTransitionSpeed ease-out, margin-left $sidebarTransitionSpeed ease-out;

}

.logoContainer{
    display: flex;
    width: $leftSideWidth;
    justify-content: center;
    align-items: center;
}

.topbarItems{
    width: 100%;
    padding-left: $paddingInnerLeftSide;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: $topBarHeight;
    overflow-x: visible;
    padding-top: 24px;
    padding-bottom: 24px;


}


.leftSide{
    display: flex;
}

.arrowContainer{
    display: flex;
    :hover{
        background-color: #a8a8a8;
        cursor: pointer;
    }

}
.arrowCircle{
    display: flex;
    align-items: center; 
    justify-content: center;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    background-color: #D9D9D9;
    margin-right: 16px;
} 
.cwd{
    align-self: center;
    font-weight: 700;
    font-size: 1.75rem;
}

.rightSide{
    display: flex;
    align-self: center;
}



.topbarSep{
    background-color: #808A87;
    width: 100%;
    height: 2px;
    z-index: 102;
    border-radius: 1px;
}


.notificationButton{
    background-color: #FFFFFF;
    position: relative;
    align-self: center;
    margin-right: 8px;
    height: 48px;
    width: 48px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
}.notificationButton:hover{
    background-color: #dddddd;
    cursor: pointer;   
}

.profile{
    display: flex;

}.profile:hover{
    cursor: pointer;

}

.profilePic{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        border-radius: 50%;
    }
}.profilePic:hover{
    background-color: #D3D5DA;
}



.hidden{
    display: none;
}
.active{
    display: block;
}

//NotificationMenu styles
.notificationMenu{
    position: fixed;
    right: $paddingInnerRightSide;
    z-index: 200;
    top: calc($topBarHeight - 15px);
    width: 290px;
    height: 199px;  
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    align-items: center;
    padding: 12px 14px;
    h4{
        align-self: flex-start;
    }
}

.notificationSep{
    width: 100%;
    height: 1px;
    background-color: #ABABAB;
    border-radius: 1px;
    margin-top: 1px;
    margin-bottom: 50px;
}


//AccountMenu styles
.accountMenu{
    position: fixed;
    z-index: 200;
    top: calc($topBarHeight - 15px);
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding-top: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.profileName{
    font-weight: 500;
    font-size: 1.25rem;
    align-self: flex-start;
    padding: 0 16px;
    p{
        margin: 0.15rem 0px;
    }
    margin-bottom: 13px;
}

.menuLink{
    /* Add top border ; flex; align-items: center; padding*/
    align-self: flex-start;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding: 13px 16px;
    border-top: 1px solid #ABABAB;
    width: 100%;
    text-decoration: none;
    color: inherit;
    p{
        margin: 0 0;
    }
} .menuLink:hover{
    cursor: pointer;
    background-color: #dddddd;
}